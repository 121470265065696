html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Inter-Light;
  src: url(../../shared/src/assets/fonts/Inter-Light.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(../../shared/src/assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(../../shared/src/assets/fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: Zodiak Variable;
  src: url(../../shared/src/assets/fonts/Zodiak%20Variable.ttf);
}